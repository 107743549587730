import request from '@/utils/request'

// 查询合同模版列表
export function listContractTemplate(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/contract_template/list',
        method: 'get',
        params: query
    })
}

// 查询合同模版详细
export function getContractTemplate(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/contract_template/' + id,
        method: 'get'
    })
}

// 新增合同模版
export function addContractTemplate(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/contract_template',
        method: 'post',
        data: data
    })
}

// 修改合同模版
export function updateContractTemplate(data) {
    return request({
        url: process.env.VUE_APP_BASE_API +'/api/business/contract_template',
        method: 'put',
        data: data
    })
}

// 删除合同模版
export function delContractTemplate(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/contract_template/' + id,
        method: 'delete'
    })
}

// 查询项目模版选项
export function getContractTemplateOptions(projectTypeId) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/contract_template/options?projectTypeId='+projectTypeId,
        method: 'get',
    })
}

// 查询项目模版参数
export function getContractParams(contractTemplateId) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/contract_template/'+contractTemplateId+'/params',
        method: 'get',
    })
}
