<template>
  <!--  图片、pdf、docx 预览
            "docx-preview": "^0.1.4",
            "jszip": "^3.10.0",-->
    <el-dialog title="文件预览" v-model="isShow" width="1200px" height="600px">
<!--      <template slot="footer">-->
<!--        <div v-if="showPdf" class="pdf-layout-page">-->
<!--          <el-button type="primary" @click="changePdfPage(0)" :disabled="currentPage===1" name="上一页"/>-->
<!--          {{currentPage}} / {{pageCount}}-->
<!--          <el-button @click="changePdfPage(1)" :disabled="currentPage===pageCount" name="下一页"/>-->
<!--        </div>-->
<!--      </template>-->
<!--      <div class="modal-body form">-->
        <div v-if="showImg">
          <el-image :src="images" preview="1" preview-text="" style="width:100%"/>
        </div>
        <div v-show="showDocx" ref="word">
          <iframe v-if="fileUrl" frameborder="0"
                  :src="fileUrl"
                  width='100%'
                  height="100%">
          </iframe>
        </div>
      <div v-show="showDoc">
        <iframe v-if="fileUrl" frameborder="0"
                :src="fileUrl"
                width='100%'
                height="600px">
        </iframe>
      </div>
<!--        <div v-show="showPdf" class="pdf-layout" id="top">-->
<!--          <pdf-view-->
<!--              ref="pdf"-->
<!--              :src="pdfPath"-->
<!--              :page="currentPage"-->
<!--              @num-pages="pageCount=$event"-->
<!--              @page-loaded="currentPage=$event"-->
<!--              @loaded="loadPdfHandler"/>-->
<!--        </div>-->
<!--      </div>-->
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">关闭</el-button>
          </span>
      </template>
    </el-dialog>
</template>

<script>
import pdfView from 'vue-pdf'
import axios from 'axios'
const docxPre = require('docx-preview')
window.JSZip = require('jszip')
export default {
  name: "FilePreview",
  components: { pdfView },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    filepath: {
      type: String,
      required: true,
    }
  },
  emits: ['update:visible'],
  data() {
    return {
      showDoc: false,//判断如果是否为word文件显示
      showPdf: false,//判断如果是否为pdf文件显示
      showImg: false,//判断如果是否为图片显示
      showDocx: false,
      fileUrl: '',//pdf链接
      images: '',//图片链接
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      pdfPath: '',
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.showView(this.filepath)
  },
  methods: {
    showView(filePath) {
      // let that = this
      let type = filePath.split('.')[filePath.split('.').length - 1]
      if (type === 'jpg' || type === 'png' || type === 'jpeg') {
        this.images = filePath
        this.showImg = true
      } else if (type === 'pdf') {
        this.loadPdfHandler()//重置pdf第一页展示
        this.pdfPath = filePath
        this.showPdf = true
      } else if (type === 'doc') {//word预览
        // this.fileUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + 'https://axtcdn.tajansoft.com/%E7%94%B5%E5%8A%A8%E8%BD%A6%E5%85%85%E7%94%B5%E6%A1%A9%E7%A9%BA%E7%99%BD%E4%B8%89%E6%96%B9%E5%90%88%E4%BD%9C%E5%8D%8F%E8%AE%AEV3.0%E6%A0%87%E5%87%86%E7%89%88%E7%9A%84%E5%89%AF%E6%9C%AC.docx';
        // this.fileUrl = 'https://view.xdocin.com/view?src='+ 'https://axtcdn.tajansoft.com/%E7%94%B5%E5%8A%A8%E8%BD%A6%E5%85%85%E7%94%B5%E6%A1%A9%E7%A9%BA%E7%99%BD%E4%B8%89%E6%96%B9%E5%90%88%E4%BD%9C%E5%8D%8F%E8%AE%AEV3.0%E6%A0%87%E5%87%86%E7%89%88%E7%9A%84%E5%89%AF%E6%9C%AC.docx';
        // this.fileUrl = 'https://view.xdocin.com/view?src=' + filePath
        this.fileUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + filePath
        // this.fileUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + 'https://charging.simbest.cn/profile/upload/2024/02/19/电动自行车充电桩三方合作协议 V5.0（通用版）(2)_20240219111644A071.doc'
        console.log(this.fileUrl)
        this.showDoc = true
      } else if (type === 'docx') {//word预览
        this.showDocx = true
        this.previewWord(filePath)
      }
    },
    // 后端返回二进制流
    previewWord(filePath) {
      let that = this
      // 这里需要提起打开弹窗，因为有时很找不到word的ref 会报错
      axios({
        method: 'get',
        responseType: 'blob', // 因为是流文件，所以要指定blob类型
        url: filePath
      }).then(({ data }) => {
        docxPre.renderAsync(data, this.$refs.word)
      })
    },
    //pdf上一页下一页操作
    changePdfPage(val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++
        this.top()
      }
    },
    top() {
      document.querySelector('#top').scrollIntoView(true)
    },
    // pdf加载时
    loadPdfHandler(e) {
      this.currentPage = 1 // 加载的时候先加载第一页
    },
    cancel() {
      this.showDoc = false//判断如果是否为word文件显示
      this.showPdf = false//判断如果是否为pdf文件显示
      this.showImg = false//判断如果是否为图片显示
      this.isShow = false
    },
    handleClose(){
      this.isShow = false
    }
  }
}
</script>

<style scoped lang="less">
.form {
  height: 600px;
  overflow: auto;
}
.pdf-layout-page {
  left: 30%;
  margin: auto;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  position: absolute
}
</style>
